<template>
  <b-row
    v-can="'order-remark-view'"
  >
    <b-table
      :items="remarks"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      hover
      responsive
      striped
      show-empty
    >
      <template #empty>
        <TableDataFetching
          :rows="remarks"
          :data-loading="dataLoading"
        />
      </template>
      <template #cell(remarkedBy)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            :text="avatarText(data.value.first_name || data.item.remarkBy.first_name)"
            size="32"
            variant="light-warning"
          />
          <div>
            <div class="font-weight-bolder ml-1">
              {{ data.value.first_name || data.item.remarkBy.first_name }}
            </div>
            <div class="font-small-2 ml-1">
              {{ data.value.last_name || data.item.remarkBy.last_name }}
            </div>
          </div>
        </div>
      </template>
      <template #cell(tags)="data">
        <div v-if="data.value">
          <div v-if="data.value.length === 0 || data.value === 'null'">
            <b-badge variant="light-danger">
              No Specified Tags
            </b-badge>
          </div>
          <div v-else>
            <div
              v-for="(tag, index) in data.value"
              :key="index"
            >
              <b-badge
                variant="light-primary"
                style="margin-bottom:5px;"
              >
                {{ tag }}
              </b-badge>
            </div>
          </div>
        </div>
        <div v-else>
          <b-badge variant="light-danger">
            No Specified Tags
          </b-badge>
        </div>
      </template>
      <template #cell(readBy)="data">
        <b-tooltip
          :target="`remark-${data.item.id}`"
          triggers="hover"
          variant="primary"
          boundary="document"
        >
          <div
            v-for="(readBy, index) in data.item.readBy"
            :key="index"
            class="d-flex justify-content-start align-items-center"
            style="margin-bottom:12px;margin-top:12px;"
          >
            <div>
              <b-avatar
                size="22"
                :text="avatarText(readBy.user_type.includes('Merchant') ? 'Merchant' : 'Staff')"
                variant="light-white"
              />
            </div>
            <div>
              <div
                class="font-weight-bolder ml-1 text-left"
                style="font-size:0.7rem;"
              >
                {{ `${readBy.user.first_name} ${readBy.user.last_name}` }}
              </div>
              <div
                class="font-weight-bold ml-1 text-left"
                style="font-size:0.6rem;"
              >
                {{ readBy.user.email }}
              </div>
            </div>
          </div>
        </b-tooltip>
        <div
          :id="`remark-${data.item.id}`"
          class="d-flex justify-content-start align-items-center remark-info-item"
        >
          <span style="margin-right:0.3rem;">
            {{ data.value.length }}
          </span>
          <feather-icon
            icon="UsersIcon"
            size="16"
          />
        </div>
      </template>
      <template #cell(created_at)="data">
        {{ __dateTimeFormatter(data.value) }}
      </template>
      <template
        #cell(action)="data"
        v-can="'order-remark-create'"
      >
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="12"
              class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item
            v-if="currentUser.id == (data.item.remarkBy ? data.item.remarkBy.id : data.item.remarkedBy.id) && (data.item.remarkBy || data.item.remarkedBy).merchant_id"
            @click="onClickEdit(data.item)"
          >
            <feather-icon icon="Edit2Icon" />
            Edit
          </b-dropdown-item>
          <b-dropdown-item
            v-if="currentUser.id == (data.item.remarkBy ? data.item.remarkBy.id : data.item.remarkedBy.id) && (data.item.remarkBy || data.item.remarkedBy).merchant_id"
            @click="onClickDelete(data.item.id)"
          >
            <feather-icon icon="XIcon" />
            Delete
          </b-dropdown-item>
          <b-dropdown-item
            v-if="currentUser.id != (data.item.remarkBy ? data.item.remarkBy.id : data.item.remarkedBy.id) || !(data.item.remarkBy || data.item.remarkedBy).merchant_id"
          >
            No Options Available
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-row>
</template>

<script>
import {
  BTable,
  BRow,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BTooltip,
} from 'bootstrap-vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { avatarText } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const remarkRepository = RepositoryFactory.get('remark')

function initialState() {
  return {
    avatarText,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    fields: [
      { key: 'remarkedBy', label: 'Remarked By', sortable: true },
      { key: 'remark', label: 'Remark', sortable: false },
      { key: 'tags', label: 'Tags', sortable: false },
      { key: 'readBy', label: 'Read By', sortable: true },
      { key: 'created_at', label: 'Remark Date', sortable: false },
      { key: 'action', label: 'Action', sortable: false },
    ],
    currentUser: {},
  }
}

export default {
  components: {
    BRow,
    BTable,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BTooltip,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    remarks: {
      type: Array,
      default: null,
    },
    setUpdate: {
      type: Function,
      default: null,
    },
    orderId: {
      type: Number,
      default: null,
    },
    dataLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return initialState()
  },
  beforeMount() {
    this.currentUser = JSON.parse(localStorage.getItem('current_user'))
    if (!this.setUpdate) this.fields.pop()
  },
  mounted() {
    this.markAsRead()
  },
  methods: {
    async onClickEdit(remark) {
      this.setUpdate(remark)
    },
    async onClickDelete(id) {
      try {
        const res = await remarkRepository.deleteRemark(id)
        if (res.status === 200) {
          this.showSuccessMessage('Remark deleted successfully')
          this.remarks = this.remarks.filter(remark => remark.id !== id)
        } else {
          this.showErrorMessage('Couldn\'t delete remark')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async markAsRead() {
      try {
        const res = await remarkRepository.markAsRead(this.orderId)
        if (res.status !== 200) this.showErrorMessage('Couldn\'t update remark read status')
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>
