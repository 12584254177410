<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          v-if="onAllOrder!='primary' && onPendindDeliver!='primary' && onToBeReturned!='primary'"
          cols="12"
          md="3"
        >
          <b-form-group
            label="Delivery Date"
            label-for="delivery-date"
          >
            <div class="position-relative">
              <flat-pickr
                v-model="filters['status_date']"
                class="form-control"
                :config="{ mode: 'range', enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                placeholder="Search Date"
              />
              <div
                v-if="filters['status_date']"
                class="flatpickr-cancel"
                @click="clearDate('status_date')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Rider Deposit Status"
            label-for="deposit_status"
          >
            <v-select
              v-model="filters['staff_deposit_status']"
              :options="financeStatusOptions"
              :reduce="option => option.key"
              label="name"
              placeholder="Select Status"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Agent Deposit Status"
            label-for="deposit_status"
          >
            <v-select
              v-model="filters['agency_deposit_status']"
              :options="financeStatusOptions"
              :reduce="option => option.key"
              label="name"
              placeholder="Select Status"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Branch Deposit Status"
            label-for="deposit_status"
          >
            <v-select
              v-model="filters['branch_deposit_status']"
              :options="financeStatusOptions"
              :reduce="option => option.key"
              label="name"
              placeholder="Select Status"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          v-if="onPendingDeposit=='primary' || onDeposit=='primary' || onApprove=='primary'"
          cols="12"
          md="3"
        >
          <b-form-group
            :label="onPendingDeposit=='primary'?'Invoice Create Date' : onDeposit=='primary'? 'Deposited Date':'Approved Date'"
            label-for="finance-status-date"
          >
            <div class="position-relative">
              <flat-pickr
                v-model="filters['invoice_status_date']"
                class="form-control"
                :config="{ mode: 'range', enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                placeholder="Search Date"
              />
              <div
                v-if="filters['invoice_status_date']"
                class="flatpickr-cancel"
                @click="clearDate('invoice_status_date')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Apply and Clear Filters -->
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <div>
      <b-row class="match-height">
        <b-col md="3">
          <b-card
            :border-variant="onAllOrder"
            class="card-transaction"
            no-body
            @click="onAllOrders()"
          >
            <b-card-body
              class="transaction-item d-flex align-items-center"
            >
              <b-media
                no-body
              >
                <b-media-aside
                  vertical-align="center"
                >
                  <b-avatar
                    rounded
                    size="42"
                    style="background-color: #67b7b7"
                  >
                    <feather-icon
                      size="18"
                      icon="MenuIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">
                    All Orders
                    <feather-icon
                      v-b-tooltip.hover.v-secondary.topright="'All orders given to the courier company by the Merchant '"
                      class="text-secondary"
                      size="12"
                      icon="AlertCircleIcon"
                    />
                  </h6>
                  <small>{{ allOrderStats.count !== undefined && allOrderStats.count !== null ? allOrderStats.count + ' Orders' : 'Loading...' }}</small>
                </b-media-body>
              </b-media>
              <div
                v-b-tooltip.hover.v-primary
                class="font-weight-bolder"
                :title="__numberWithCommas(allOrderStats.total, true)"
              >
                {{ businessProfileCurrency || localStorageCurrencyValue }} {{ kFormatter(allOrderStats.total) || '0' }}
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card
            :border-variant="onPendindDeliver"
            class="card-transaction"
            no-body
            @click="onPendingDelivery()"
          >
            <b-card-body
              class="transaction-item d-flex align-items-center"
            >
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                >
                  <b-avatar
                    rounded
                    size="42"
                    style="background-color: #6a76b0"
                  >
                    <feather-icon
                      size="18"
                      icon="RepeatIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">
                    Pending Delivery
                    <feather-icon
                      v-b-tooltip.hover.v-secondary.topright="'All Merchant orders except delivered and returned orders.'"
                      class="text-secondary"
                      size="12"
                      icon="AlertCircleIcon"
                    />
                  </h6>
                  <small>{{ pendingDeliveryStats.count !== undefined && pendingDeliveryStats.count !== null ? pendingDeliveryStats.count + ' Orders' : 'Loading...' }}</small>
                </b-media-body>
              </b-media>
              <div
                v-b-tooltip.hover.v-primary
                class="font-weight-bolder"
                :title="__numberWithCommas(pendingDeliveryStats.total, true)"
              >
                {{ businessProfileCurrency || localStorageCurrencyValue }} {{ kFormatter(pendingDeliveryStats.total) || '0' }}
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card
            :border-variant="onToBeReturned"
            class="card-transaction"
            no-body
            @click="onToBeReturn()"
          >
            <b-card-body
              class="transaction-item d-flex align-items-center"
            >
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                >
                  <b-avatar
                    rounded
                    size="42"
                    style="background-color: #64c778"
                  >
                    <feather-icon
                      size="18"
                      icon="Minimize2Icon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">
                    To Be Returned
                    <feather-icon
                      v-b-tooltip.hover.v-secondary.topright="'All Merchant orders that are either returned to the client or ready to be returned to the client.'"
                      class="text-secondary"
                      size="12"
                      icon="AlertCircleIcon"
                    />
                  </h6>
                  <small>{{ returnOrdersStats.count !== undefined && returnOrdersStats.count !== null ? returnOrdersStats.count + ' Orders' : 'Loading...' }}</small>
                </b-media-body>
              </b-media>
              <div
                v-b-tooltip.hover.v-primary
                class="font-weight-bolder"
                :title="__numberWithCommas(returnOrdersStats.total, true)"
              >
                {{ businessProfileCurrency || localStorageCurrencyValue }} {{ kFormatter(returnOrdersStats.total) || '0' }}
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card
            :border-variant="onDeliverdOrders"
            class="card-transaction"
            no-body
            @click="onDelivered()"
          >
            <b-card-body
              class="transaction-item d-flex align-items-center"
            >
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                >
                  <b-avatar
                    rounded
                    size="42"
                    style="background-color: #48d765"
                  >
                    <feather-icon
                      size="18"
                      icon="CheckSquareIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">
                    Delivered Orders
                    <feather-icon
                      v-b-tooltip.hover.v-secondary.topright="'All Merchant Orders that are either delivered, delivered by pickup rider or partially delivered. '"
                      class="text-secondary"
                      size="12"
                      icon="AlertCircleIcon"
                    />
                  </h6>
                  <small>{{ deliveredOrdersStats.count !== undefined && deliveredOrdersStats.count !== null ? deliveredOrdersStats.count + ' Orders' : 'Loading...' }}</small>
                </b-media-body>
              </b-media>
              <div
                v-b-tooltip.hover.v-primary
                class="font-weight-bolder"
                :title="__numberWithCommas(deliveredOrdersStats.total, true)"
              >
                {{ businessProfileCurrency || localStorageCurrencyValue }} {{ kFormatter(deliveredOrdersStats.total) || '0' }}
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="match-height">
        <!-- <b-col md="3">
          <b-card
            :border-variant="onReturnedOrders"
            class="card-transaction"
            no-body
            @click="onReturned()"
          >
            <b-card-body
              class="transaction-item d-flex align-items-center"
            >
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                >
                  <b-avatar
                    rounded
                    size="42"
                    style="background-color: #c26421"
                  >
                    <feather-icon
                      size="18"
                      icon="ArrowLeftCircleIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">
                    Returned Orders
                    <feather-icon
                      v-b-tooltip.hover.v-secondary.topright="'All returned orders and received to the merchant.'"
                      class="text-secondary"
                      size="12"
                      icon="AlertCircleIcon"
                    />
                  </h6>
                  <small>{{ finance.all_return_orders.count }} Orders</small>
                </b-media-body>
              </b-media>
              <div
                class="font-weight-bolder"
              >
                {{ businessProfileCurrency || localStorageCurrencyValue }} {{ __numberWithCommas(finance.all_return_orders.sum) || '0' }}
              </div>
            </b-card-body>
          </b-card>
        </b-col> -->
        <!-- <b-col md="3">
          <b-card
            :border-variant="onTotalFinance"
            class="card-transaction"
            no-body
            @click="onTotalFinanced()"
          >
            <b-card-body
              class="transaction-item d-flex align-items-center"
            >
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                >
                  <b-avatar
                    rounded
                    size="42"
                    style="background-color: #10882b"
                  >
                    <feather-icon
                      size="18"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <div
                    class="d-flex justify-content-between"
                  >
                    <h6 class="transaction-title">
                      Total Finance
                      <feather-icon
                        v-b-tooltip.hover.v-secondary.topright="'Total order value in all statuses by deducting delivery charge + commissions.'"
                        class="text-secondary"
                        size="12"
                        icon="AlertCircleIcon"
                      />
                    </h6>
                  </div>
                  <small>{{ finance.all_orders_net_amount.count || 'N/A' }} Orders</small>
                </b-media-body>
              </b-media>
              <div
                class="font-weight-bolder"
              >
                {{ businessProfileCurrency || localStorageCurrencyValue }} {{ __numberWithCommas(finance.all_orders_net_amount.total) || '0' }}
              </div>
            </b-card-body>
          </b-card>
        </b-col> -->
        <b-col md="3">
          <b-card
            :border-variant="onToBeInvoice"
            class="card-transaction"
            no-body
            @click="onToBeInvoiced()"
          >
            <b-card-body
              class="transaction-item d-flex align-items-center"
            >
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                >
                  <b-avatar
                    rounded
                    size="42"
                    style="background-color: #48c2d7"
                  >
                    <feather-icon
                      size="18"
                      icon="ClipboardIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <div
                    class="d-flex justify-content-between"
                  >
                    <h6 class="transaction-title">
                      To Be Invoiced
                      <feather-icon
                        v-b-tooltip.hover.v-secondary.topright="'Total of delivered & returned orders, but not assigned to any invoice yet(only the calculation of credit orders are included here).'"
                        class="text-secondary"
                        size="12"
                        icon="AlertCircleIcon"
                      />
                    </h6>
                  </div>
                  <small>{{ toBeInvoicedStats.count !== undefined && toBeInvoicedStats.count !== null ? toBeInvoicedStats.count + ' Orders' : 'Loading...' }}</small>
                </b-media-body>
              </b-media>
              <div
                v-b-tooltip.hover.v-primary
                class="font-weight-bolder"
                :title="__numberWithCommas(toBeInvoicedStats.total, true)"
              >
                {{ businessProfileCurrency || localStorageCurrencyValue }} {{ kFormatter(toBeInvoicedStats.total) || '0' }}
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card
            :border-variant="onPendingDeposit"
            class="card-transaction"
            no-body
            @click="onPendingDeposits()"
          >
            <b-card-body
              class="transaction-item d-flex align-items-center"
            >
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                >
                  <b-avatar
                    rounded
                    size="42"
                    style="background-color: #adb65c"
                  >
                    <feather-icon
                      size="18"
                      icon="RefreshCwIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <div
                    class="d-flex"
                  >
                    <h6 class="transaction-title">
                      Pending Deposit
                      <feather-icon
                        v-b-tooltip.hover.v-secondary.topright="'Total of delivered & returned orders and assigned to a invoice, but not paid yet.(only the calculation of credit orders are included here)'"
                        class="text-secondary"
                        size="12"
                        icon="AlertCircleIcon"
                      />
                    </h6>
                  </div>
                  <small>{{ pendingDepositStats.count !== undefined && pendingDepositStats.count !== null ? pendingDepositStats.count + ' Orders' : 'Loading...' }}</small>
                </b-media-body>
              </b-media>
              <div
                v-b-tooltip.hover.v-primary
                class="font-weight-bolder"
                :title="__numberWithCommas(pendingDepositStats.total, true)"
              >
                {{ businessProfileCurrency || localStorageCurrencyValue }} {{ kFormatter(pendingDepositStats.total) || '0' }}
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card
            :border-variant="onDeposit"
            class="card-transaction"
            no-body
            @click="onDeposits()"
          >
            <b-card-body
              class="transaction-item d-flex align-items-center"
            >
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                >
                  <b-avatar
                    rounded
                    size="42"
                    style="background-color: #70af51"
                  >
                    <feather-icon
                      size="18"
                      icon="CheckIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <div
                    class="d-flex justify-content-between"
                  >
                    <h6 class="transaction-title">
                      Deposited
                      <feather-icon
                        v-b-tooltip.hover.v-secondary.topright="'Total of delivered & returned orders and assigned to a invoice and paid(only the calculation of credit orders are included here).'"
                        class="text-secondary"
                        size="12"
                        icon="AlertCircleIcon"
                      />
                    </h6>
                  </div>
                  <small>{{ depositedStats.count !== undefined && depositedStats.count !== null ? depositedStats.count + ' Orders' : 'Loading...' }}</small>
                </b-media-body>
              </b-media>
              <div
                v-b-tooltip.hover.v-primary
                class="font-weight-bolder"
                :title="__numberWithCommas(depositedStats.total, true)"
              >
                {{ businessProfileCurrency || localStorageCurrencyValue }} {{ kFormatter(depositedStats.total) || '0' }}
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card
            :border-variant="onApprove"
            class="card-transaction"
            no-body
            @click="onApproved()"
          >
            <b-card-body
              class="transaction-item d-flex align-items-center"
            >
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                >
                  <b-avatar
                    rounded
                    size="42"
                    style="background-color: #b78164"
                  >
                    <feather-icon
                      size="18"
                      icon="CheckCircleIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <div
                    class="d-flex justify-content-between"
                  >
                    <h6 class="transaction-title">
                      Approved
                      <feather-icon
                        v-b-tooltip.hover.v-secondary.topright="'Total of delivered & returned orders and assigned to a invoice and paid and approved by merchant.(only the calculation of credit orders are included here)'"
                        class="text-secondary"
                        size="12"
                        icon="AlertCircleIcon"
                      />
                    </h6>
                  </div>
                  <small>{{ approvedStats.count !== undefined && approvedStats.count !== null ? approvedStats.count + ' Orders' : 'Loading...' }}</small>
                </b-media-body>
              </b-media>
              <div
                v-b-tooltip.hover.v-primary
                class="font-weight-bolder"
                :title="__numberWithCommas(approvedStats.total, true)"
              >
                {{ businessProfileCurrency || localStorageCurrencyValue }} {{ kFormatter(approvedStats.total) || '0' }}
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-card
        no-body
      >
        <div>
          <div
            class="m-2"
          >
            <!-- input -->
            <b-row>
              <b-col
                md="12"
                class="d-flex justify-content-end mb-1 mb-md-0"
              >
                <TableButtons
                  :items="rows"
                  :json_fieldz="json_fields"
                  :name="`Overview - Page ${page}.xls`"
                  :bulkname="`Overview.xls`"
                  :fetch="fetchOrderListNoPagination"
                  @refresh="onClickRefresh"
                />
              </b-col>
            </b-row>
          </div>

          <!-- table -->
          <b-overlay
            id="overlay-background"
            variant="light"
            opacity="0.30"
            blur="10px"
            rounded="sm"
          >
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="rows"
              :fields="fields"
              show-empty
              sticky-header="100vh"
              class="hide-vertical-scroll"
              @sort-changed="sortChanged"
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template #cell(waybill_number)="data">
                <div class="d-flex align-items-center">
                  <b-avatar
                    :text="avatarText(data.item.customer_name)"
                    size="32"
                    variant="light-primary"
                  />
                  <div>
                    <div
                      class="font-weight-bolder ml-1 text-primary"
                      @click="onClickViewOrder(data.item.id)"
                    >
                      {{ data.value }}
                    </div>
                    <div class="font-small-2 ml-1">
                      {{ __dateTimeFormatter(data.item.created_at) }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(customer_email)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      {{ data.item.customer_name }}
                    </div>
                    <div class="font-small-2">
                      {{ data.value }}
                    </div>
                    <div class="font-small-2">
                      <div><b>Primary number: </b>{{ data.item.customer_phone }}</div>
                    </div>
                    <div
                      v-if="data.item.customer_secondary_phone !== null"
                      class="font-small-2"
                    >
                      <div><b>Second number: </b>{{ data.item.customer_secondary_phone }}</div>
                    </div>
                    <div class="font-small-2">
                      {{ data.item.customer_address }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(customer_type)="data">
                <div class="d-flex justify-content-center">
                  {{ data.item.order_setting.merchant_type }}
                </div>
              </template>
              <template #cell(merchant_invoice)="data">
                <div
                  class="d-flex justify-content-center font-weight-bolder"
                  style="margin-bottom:2px;"
                >
                  {{ data.value.ref_no || '---' }}
                </div>
                <div class="d-flex justify-content-center">
                  <finance-status-badge
                    :status-key="data.value.statuses? data.value.statuses[0].name : ''"
                    :finance-status="financeStatusOptions"
                  />
                </div>
              </template>
              <template #cell(cod)="data">
                <span>{{ __numberWithCommas(data.value, true) }}</span>
              </template>
              <template #cell(collected_cod)="data">
                <span>{{ __numberWithCommas(data.value, true) }}</span>
              </template>
              <template #cell(delivery_charge)="data">
                <span>{{ __numberWithCommas(data.value, true) }}</span>
              </template>
              <template #cell(created_at)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>
              <template #cell(status.key)="data">
                <order-status-badge
                  :status-key="data.value"
                />
              </template>
              <template #cell(action)="data">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="12"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="onClickViewOrder(data.item.id)"
                  >
                    <feather-icon icon="EyeIcon" />
                    View Details
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  class="d-flex align-items-center justify-content-sm-start"
                  md="3"
                >
                  <label>Per page</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block width-100 ml-1"
                  />
                </b-col>
                <!-- Pagination -->
                <b-col
                  class="d-flex align-items-center justify-content-sm-end"
                  md="9"
                >
                  <span class="text-muted pagination-text mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                  <b-pagination
                    v-model="page"
                    :per-page="perPage"
                    :total-rows="(meta.total)? meta.total : 0"
                    class="mb-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </div>
      </b-card>
    </div>
    <view-order-modal ref="viewOrderModal" />
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCol,
  BPagination,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  VBTooltip,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BFormGroup,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import OrderStatusBadge from '@core/components/status/OrderStatusBadge.vue'
import FinanceStatusBadge from '@core/components/status/FinanceStatusBadge.vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import displayAmountWithCurrency from '@core/mixins/currency'
import { avatarText, kFormatter } from '@core/utils/filter'
import ViewOrderModal from '@/components/orders/ViewOrderModal.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store/index'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const FinanceRepository = RepositoryFactory.get('finance')
// eslint-disable-next-line no-unused-vars
const BusinessSettingsRepository = RepositoryFactory.get('businessSettings')
const OverviewDashboardRepository = RepositoryFactory.get('overviewdashboard')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BAvatar,
    BPagination,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BCardActions,
    vSelect,
    OrderStatusBadge,
    FinanceStatusBadge,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    ViewOrderModal,
    flatPickr,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage, displayAmountWithCurrency],
  data() {
    return {
      businessProfileCurrency: null,
      localStorageCurrencyValue: localStorage.getItem('businessCurrency') === 'ALL' ? 'LKR' : localStorage.getItem('businessCurrency'),
      allOrderStats: { total: null, count: undefined },
      pendingDeliveryStats: { total: null, count: undefined },
      returnOrdersStats: { total: null, count: undefined },
      deliveredOrdersStats: { total: null, count: undefined },
      toBeInvoicedStats: { total: null, count: undefined },
      pendingDepositStats: { total: null, count: undefined },
      depositedStats: { total: null, count: undefined },
      approvedStats: { total: null, count: undefined },
      // Filters Start
      filters: {},
      statusOptions: [],
      financeStatusOptions: [],
      warehouseOptions: [],
      riderOptions: [],
      finance: [],
      options: {
        delimiter: {
          delimiter: ' to ',
          blocks: [4, 4],
          uppercase: true,
        },
      },
      // Filters End
      avatarText,
      total: 0,
      meta: {},
      dataLoading: false,
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      selectedKey: '',
      filterName: '',
      fields: [
        {
          key: 'waybill_number',
          label: 'WayBill Number',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'customer_email',
          label: 'Customer',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'customer_type',
          label: 'Customer Type',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'cod',
          label: 'COD',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'collected_cod',
          label: 'Collected Cod',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'delivery_charge',
          label: 'Delivery Charge',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'merchant_invoice',
          label: 'Invoice',
          sortable: true,
          thClass: ' text-center',
          tdClass: ' text-center',
        },
        {
          key: 'status.key',
          label: 'Status',
          sortable: true,
          thClass: 'text-center',
          tdClass: ' text-center',
        },
        { key: 'action', label: 'Action', sortable: false },
      ],
      json_fields: {
        'Waybill Number': 'waybill_number',
        'Customer Name': {
          field: 'customer_name',
          callback: value => `"${value}"`,
        },
        'Customer Type': {
          callback: value => value.order_setting.merchant_type,
        },
        'Customer Email': {
          field: 'customer_email',
          callback: value => `"${value}"`,
        },
        'Customer Address': {
          field: 'customer_address',
          callback: value => `"${value}"`, // Wrap the address in double quotes
        },
        'Customer Phone': 'customer_phone',
        'Second Phone Number': 'customer_secondary_phone',
        COD: {
          field: 'cod',
        },
        'Delivery Charge': {
          field: 'delivery_charge',
        },
        'Collected Cod': 'collected_cod',
        'Merchant Invoice': {
          callback: value => `${value.merchant_invoice.ref_no || 'N/A'}`,
        },
        Weight: 'weight',
        Status: 'status.name',
        Description: {
          field: 'description',
          callback: value => `"${value}"`,
        },
      },
      rows: [],
      selectedWaybillArray: [],
      selectedSection: 'allOrders',
      onAllOrder: 'primary',
      onPendindDeliver: '',
      onToBeReturned: '',
      onDeliverdOrders: '',
      onReturnedOrders: '',
      onTotalFinance: '',
      onToBeInvoice: '',
      onPendingDeposit: '',
      onDeposit: '',
      onApprove: '',
      key: '',
      attribute: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    page() {
      this.fetchOrderList()
    },
    perPage() {
      this.fetchOrderList()
    },
  },
  async mounted() {
    this.totalRows = this.rows.length
    await this.initPageData()
  },
  methods: {
    kFormatter,
    async initPageData() {
      try {
        await Promise.all([
          this.financeStatusOptions = FinanceStatusBadge.data().statusList,
          this.getBusinessCurrency(),
          this.fetchOrderList(),
          this.readFinanceStatsData(),
        ])
        this.fetchOrderList()
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async readFinanceStatsData() {
      try {
        this.setStatsLoadingState()
        this.readDataforAllOrderStats(this.filters)
        this.readDataforPendingDeliveryStats(this.filters)
        this.readDataforReturnOrdersStats(this.filters)
        this.readDataforDeliveredOrdersStats(this.filters)
        this.readDataforToBeInvoicedStats(this.filters)
        this.readDataforPendingDepositStats(this.filters)
        this.readDataforDepositedStats(this.filters)
        this.readDataforApprovedStats(this.filters)
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    setStatsLoadingState() {
      const statsObjects = [
        'allOrderStats',
        'pendingDeliveryStats',
        'returnOrdersStats',
        'deliveredOrdersStats',
        'toBeInvoicedStats',
        'pendingDepositStats',
        'depositedStats',
        'approvedStats',
      ]
      statsObjects.forEach(stat => {
        this[stat].count = undefined
        this[stat].total = null
      })
    },
    getBusinessCurrency() {
      try {
        const { data: { company_currency } } = (BusinessSettingsRepository.getbusinessSettingsResource()).data
        if (localStorage.getItem('businessCurrency') === true) {
          localStorage.removeItem('businessCurrency')
        }
        localStorage.setItem('businessCurrency', company_currency)
        // eslint-disable-next-line camelcase
        this.businessProfileCurrency = company_currency
      } catch (e) {
        this.convertAndNotifyError(e)
        this.businessProfileCurrency = localStorage.getItem('businessCurrency') || ''
      }
    },
    async readDataforAllOrderStats() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await OverviewDashboardRepository.getAllOrdersStatsFinance(this.filterQuery))
        this.allOrderStats.total = data.all_orders.total
        this.allOrderStats.count = data.all_orders.count
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforPendingDeliveryStats() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await OverviewDashboardRepository.getPendingDeliveryStatsFinance(this.filterQuery))
        this.pendingDeliveryStats.total = data.pending_delivery_orders.total
        this.pendingDeliveryStats.count = data.pending_delivery_orders.count
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforReturnOrdersStats() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await OverviewDashboardRepository.getReturnedOrdersStatsFinance(this.filterQuery))
        this.returnOrdersStats.total = data.returned_orders.total
        this.returnOrdersStats.count = data.returned_orders.count
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforDeliveredOrdersStats() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await OverviewDashboardRepository.getDeliveredOrdersStatsFinance(this.filterQuery))
        this.deliveredOrdersStats.total = data.delivered_orders.total
        this.deliveredOrdersStats.count = data.delivered_orders.count
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforToBeInvoicedStats() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await OverviewDashboardRepository.getToBeInvoicedStatsFinance(this.filterQuery))
        this.toBeInvoicedStats.total = data.to_be_invoiced.total
        this.toBeInvoicedStats.count = data.to_be_invoiced.count
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforPendingDepositStats() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await OverviewDashboardRepository.getPendingDepositStatsFinance(this.filterQuery))
        this.pendingDepositStats.total = data.pending_deposit.total
        this.pendingDepositStats.count = data.pending_deposit.count
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforDepositedStats() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await OverviewDashboardRepository.getDepositedStatsFinance(this.filterQuery))
        this.depositedStats.total = data.deposited.total
        this.depositedStats.count = data.deposited.count
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforApprovedStats() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await OverviewDashboardRepository.getApprovedStatsFinance(this.filterQuery))
        this.approvedStats.total = data.approved.total
        this.approvedStats.count = data.approved.count
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchOrderList() {
      this.dataLoading = true
      this.rows = []
      try {
        if (this.selectedSection === 'allOrders') {
          // eslint-disable-next-line no-underscore-dangle
          this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
          const { data } = (await FinanceRepository.getMerchantOverviewFinance(this.page, this.filterQuery, this.perPage))
          this.rows = data.data
          this.meta = data.meta
        } else {
          const { data } = (await FinanceRepository.getMerchantOverviewFinanceByKey(this.page, this.attribute, this.key, this.filterQuery, this.perPage))
          this.rows = data.data
          this.meta = data.meta
        }
      } catch (e) {
        this.showErrorMessage(e)
      }
      this.dataLoading = false
    },
    async fetchOrderListNoPagination() {
      try {
        if (this.selectedSection === 'allOrders') {
          // eslint-disable-next-line no-underscore-dangle
          this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
          const { data } = (await FinanceRepository.getMerchantOverviewFinanceNoPagination(this.filterQuery))
          return data.data
        // eslint-disable-next-line no-else-return
        } else {
          const { data } = (await FinanceRepository.getMerchantOverviewFinanceByKeyNoPagination(this.attribute, this.key, this.filterQuery))
          return data.data
        }
      } catch (e) {
        this.showErrorMessage(e)
        return []
      }
    },
    onAllOrders() {
      this.selectedSection = 'allOrders'
      this.onAllOrder = 'primary'
      this.onPendindDeliver = ''
      this.onToBeReturned = ''
      this.onDeliverdOrders = ''
      this.onReturnedOrders = ''
      this.onTotalFinance = ''
      this.onToBeInvoice = ''
      this.onPendingDeposit = ''
      this.onDeposit = ''
      this.onApprove = ''
      this.attribute = ''
      this.key = 'key_2,key_4,key_5,key_6,key_7,key_8,key_9,key_10,key_11,key_12,key_13,key_14,key_15,key_16,key_17,key_18,key_19,key_20,key_21'
      delete this.json_fields['Delivered Date']
      this.fetchOrderList()
    },
    onPendingDelivery() {
      this.selectedSection = 'pendingDelivery'
      this.onAllOrder = ''
      this.onPendindDeliver = 'primary'
      this.onToBeReturned = ''
      this.onDeliverdOrders = ''
      this.onReturnedOrders = ''
      this.onTotalFinance = ''
      this.onToBeInvoice = ''
      this.onPendingDeposit = ''
      this.onDeposit = ''
      this.onApprove = ''
      this.attribute = 'status'
      this.key = 'key_2,key_4,key_5,key_6,key_8,key_9,key_10,key_11,key_12,key_15,key_16,key_22,key_26,key_27,key_28,key_29'
      delete this.json_fields['Delivered Date']
      this.fetchOrderList()
    },
    onToBeReturn() {
      this.selectedSection = 'toBeDelivered'
      this.onAllOrder = ''
      this.onPendindDeliver = ''
      this.onToBeReturned = 'primary'
      this.onDeliverdOrders = ''
      this.onReturnedOrders = ''
      this.onTotalFinance = ''
      this.onToBeInvoice = ''
      this.onPendingDeposit = ''
      this.onDeposit = ''
      this.onApprove = ''
      this.attribute = 'status'
      this.key = 'key_17,key_18,key_19,key_20,key_21'
      delete this.json_fields['Delivered Date']
      this.fetchOrderList()
    },
    onDelivered() {
      this.selectedSection = 'toBeDelivered'
      this.onAllOrder = ''
      this.onPendindDeliver = ''
      this.onToBeReturned = ''
      this.onDeliverdOrders = 'primary'
      this.onReturnedOrders = ''
      this.onTotalFinance = ''
      this.onToBeInvoice = ''
      this.onPendingDeposit = ''
      this.onDeposit = ''
      this.onApprove = ''
      this.attribute = 'status'
      this.key = 'key_7,key_13,key_14'
      delete this.json_fields['Delivered Date']
      this.fetchOrderList()
    },
    onReturned() {
      this.selectedSection = 'toBeDelivered'
      this.onAllOrder = ''
      this.onPendindDeliver = ''
      this.onToBeReturned = ''
      this.onDeliverdOrders = ''
      this.onReturnedOrders = 'primary'
      this.onTotalFinance = ''
      this.onToBeInvoice = ''
      this.onPendingDeposit = ''
      this.onDeposit = ''
      this.onApprove = ''
      this.attribute = 'status'
      this.key = 'key_20,key_21'
      delete this.json_fields['Delivered Date']
      this.fetchOrderList()
    },
    onTotalFinanced() {
      this.selectedSection = 'allOrders'
      this.onAllOrder = ''
      this.onPendindDeliver = ''
      this.onToBeReturned = ''
      this.onDeliverdOrders = ''
      this.onReturnedOrders = ''
      this.onTotalFinance = 'primary'
      this.onToBeInvoice = ''
      this.onPendingDeposit = ''
      this.onDeposit = ''
      this.onApprove = ''
      this.attribute = ''
      this.key = ''
      delete this.json_fields['Delivered Date']
      this.fetchOrderList()
    },
    onToBeInvoiced() {
      this.selectedSection = 'toBeInvoiced'
      this.onAllOrder = ''
      this.onPendindDeliver = ''
      this.onToBeReturned = ''
      this.onDeliverdOrders = ''
      this.onReturnedOrders = ''
      this.onTotalFinance = ''
      this.onToBeInvoice = 'primary'
      this.onPendingDeposit = ''
      this.onDeposit = ''
      this.onApprove = ''
      this.attribute = 'merchant_invoice_id'
      this.filters = {
        status: ['key_7,key_13,key_14,key_20,key_21'],
      }
      this.json_fields['Delivered Date'] = 'status_date'
      this.key = 'false&filter[status]=key_7,key_13,key_14,key_20,key_21'
      this.fetchOrderList()
    },
    onPendingDeposits() {
      this.selectedSection = 'pending Deposit'
      this.onAllOrder = ''
      this.onPendindDeliver = ''
      this.onToBeReturned = ''
      this.onDeliverdOrders = ''
      this.onReturnedOrders = ''
      this.onTotalFinance = ''
      this.onToBeInvoice = ''
      this.onPendingDeposit = 'primary'
      this.onDeposit = ''
      this.onApprove = ''
      this.attribute = 'merchant_invoice'
      this.key = 'f_key_1'
      delete this.json_fields['Delivered Date']
      this.fetchOrderList()
    },
    onDeposits() {
      this.selectedSection = 'Deposit'
      this.onAllOrder = ''
      this.onPendindDeliver = ''
      this.onToBeReturned = ''
      this.onDeliverdOrders = ''
      this.onReturnedOrders = ''
      this.onTotalFinance = ''
      this.onToBeInvoice = ''
      this.onPendingDeposit = ''
      this.onDeposit = 'primary'
      this.onApprove = ''
      this.attribute = 'merchant_invoice'
      this.key = 'f_key_2'
      delete this.json_fields['Delivered Date']
      this.fetchOrderList()
    },
    onApproved() {
      this.selectedSection = 'Approved'
      this.onAllOrder = ''
      this.onPendindDeliver = ''
      this.onToBeReturned = ''
      this.onDeliverdOrders = ''
      this.onReturnedOrders = ''
      this.onTotalFinance = ''
      this.onToBeInvoice = ''
      this.onPendingDeposit = ''
      this.onDeposit = ''
      this.onApprove = 'primary'
      this.attribute = 'merchant_invoice'
      this.key = 'f_key_3'
      delete this.json_fields['Delivered Date']
      this.fetchOrderList()
    },
    onClickViewOrder(id) {
      this.$refs.viewOrderModal.openModal(id)
    },
    onClickSelectAll() {
      this.selectedWaybillArray = this.rows.map(n => n.waybill_number)
    },
    onClickDeselctAll() {
      this.selectedWaybillArray = []
    },
    onClickPrintDispatch() {
      this.$router.push({
        name: 'print-pod',
        params: {
          selectedWaybills: this.selectedWaybillArray,
        },
      })
    },
    // REQUIRED
    applyFilters() {
      this.readFinanceStatsData()
      this.fetchOrderList()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchOrderList()
      })
    },
    handleChangePage(page) {
      this.page = page
      this.fetchOrderList()
    },
    changePage(value) {
      this.perPage = value
    },
    onClickMakeRemark(id) {
      this.$refs.add_make_remark.openModal(id)
    },
    onClickMakeFutureRequest(id) {
      this.$refs.add_future_request.openModal(id)
    },
    // REQUIRED
    sortChanged(e) {
      const key = e.sortBy
      if (key) {
        const active = e.sortDesc ? '-' : ''
        this.sort = active + key
        this.fetchOrderList()
      }
    },
    onClickRefresh() {
      this.onClickDeselctAll()
      this.fetchOrderList()
      this.readFinanceStatsData()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
  .tile-warning {
    margin-bottom: 1.5em;
    color: #EB1D36;
  }
  @media (max-width: 264px) {
    .transaction-item {
      flex-direction: column;
    }
  }
</style>
