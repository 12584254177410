<template>
  <div>
    <b-badge :variant="'light-' + statusColor">
      {{ statusName }}
    </b-badge>
  </div>
</template>

<script>
import {
  BBadge,
} from 'bootstrap-vue'

export default {
  name: 'FinanceStatusBadge',
  components: {
    BBadge,
  },
  props: {
    statusKey: {
      type: String,
      default: 'N/A',
    },
  },
  data() {
    return {
      statusList: [
        {
          key: 'f_key_1',
          name: 'Pending',
          color: 'danger',
        },
        {
          key: 'f_key_2',
          name: 'Deposited',
          color: 'primary',
        },
        {
          key: 'f_key_3',
          name: 'Approved',
          color: 'success',
        },
      ],
    }
  },
  computed: {
    statusColor() {
      const obj = this.statusList.find(n => n.key === this.statusKey)
      return obj ? obj.color : 'primary'
    },
    statusName() {
      const obj = this.statusList.find(n => n.key === this.statusKey)
      return obj ? obj.name : 'N/A'
    },
  },
}
</script>

<style scoped>
</style>
