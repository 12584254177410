export default {
  methods: {
    displayAmountWithCurrency(amount) {
      // eslint-disable-next-line camelcase
      const currency_code = 'Rs '
      // eslint-disable-next-line no-underscore-dangle
      const formattedAmount = this.__numberWithCommas((amount), true)
      // eslint-disable-next-line camelcase
      return `${currency_code} ${formattedAmount}`
    },
  },
}
